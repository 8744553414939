<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸联合创始人李佐凡：将“科技赋能投资”作为自己的终身事业
              </p>
              <div class="fst-italic mb-2">2022年6月24日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >人物</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >联合创始人</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸人物</div>
                  <div class="text-muted">Non-convex People</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/10/01-李佐凡.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    “喜欢数学，热爱编程，逻辑清晰”，这是李佐凡留给我的最初印象。12年前，李佐凡凭借着一股“初生牛犊不怕虎”的勇气进入数智交易领域，面对华尔街这群聪明的人，丝毫没有显露出胆怯和慌张。华尔街固有万千魅力，但对李佐凡而言，他更期待在中国施展拳脚，梦想把“科技赋能投资”作为自己的终身事业来完成，从而为行业带来更多的效率价值。
                  </p>
                  <h4 class="my-4">一、以梦为马，坚守本心</h4>
                  <p>
                    非科班出身的李佐凡，进入金融行业并非误打误撞，而是他一直以来的梦想。2010年，李佐凡从美国宾夕法尼亚大学计算机专业毕业后，去了华尔街资管之王BlackRock（贝莱德）。他说，“那几年正好是对冲基金在美国最火的时候，身边很多朋友都找了华尔街对冲基金的工作。”
                  </p>
                  <p>
                    在紧张而自由的氛围中，李佐凡很快适应了这份工作。在工作中表现出超强的编程能力，既能灵活应对定价模型中的数学公式，也能在短时间内完成同事提出的各种需求。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/10/02-李佐凡.jpg"
                      alt="人才招聘"
                    />
                  </figure>
                  <p>
                    谈及选择金融的原因，李佐凡表示父亲是学数学出身的经济学教授，从小在环境的影响下，对经济一点儿也不陌生。“在计算机程序中你可以创造一个全新的世界，这在金融领域同样适用并能带来非常大的便利。”他是这样说的。
                  </p>
                  <p>
                    不仅如此，少年时期的李佐凡很喜欢挑战各种可能性。他从小就拿遍计算机、数学、物理、化学各种竞赛奖项，一路被保送到上海交通大学ACM班。工作后，还抽空拿了纽约年度桥牌锦标赛冠军奖杯。
                  </p>
                  <p>
                    对于生活，李佐凡一直保持着对桥牌和足球的这份热爱；对于工作，李佐凡也一直延续着对数智交易的热情、认知和挑战。他觉得，“敲键盘就可以完成的一般性工作，也许只用了自己10%的智商，而交易就不一样，可以让我的头脑高速运转。”2013年，李佐凡决定回国做自己的数智交易事业。“相较于成熟市场，数智交易在中国依然是一片广阔天地，大有可为。”他说道。
                  </p>
                  <p>
                    回国后的李佐凡，做过证券公司研究员，接触过私募、公募、券商，这些经历让他更加明确了自己的发展方向。最终把目光投向了更有科技感和挑战感的战场——算法交易，可以说是符合他本心的选择。
                  </p>
                  <h4 class="my-4">二、对技术的极致追求</h4>
                  <p >
                    经历过“刀枪实战”的李佐凡，在算法交易领域绝对是一员资深骁将。如今担任非凸科技联合创始人兼CTO，主要负责策略研究。他常说，“当市场发生变化时，需要技术和研究协同去解决。技术并非看起来像是游戏中的辅助角色，而是团队中取得胜利不可或缺的独立角色。”
                  </p>
                  <p >
                    来非凸科技，对李佐凡而言最大的吸引力在于非凸工程师文化。从上学到加入贝莱德，再到后面的自主创业，李佐凡一直在做自己感兴趣的事，也在尝试自己的可能性。他说，“现在非凸团队鼓励大家敢于尝试，勇于创新。在小而敏的团队里，能发挥出我的更多价值。”
                  </p>
                  <p >
                    在工程师眼里，世界上最美的语言就是编程语言。近年来，非凸科技一直深耕技术，追求极致，与此同时，对工程师的技术实力和技术耐性也要求极高。李佐凡表示，“非凸将Rust作为技术栈，而非C++，这很极客！对于拥有工程师天花板的非凸来说，这极大地提高了开发效率。”
                  </p>
                  <p >
                    Rust以其先进的安全并发能力而闻名，不仅拥有Java的开发效率和代码安全，还拥有C/C++的高性能。非凸科技基于Rust生态构建的算法交易系统，达到了纳秒级别的延时优化，为客户带来了最大化的收益。“作为Rust先行者，我们极力推广Rust语言，乐于分享前沿技术，鼓励更多的人学习交流。”李佐凡说。
                  </p>
                  <p >
                    从策略研究到实盘交易，每一个细微的技术环节，都需要精心打磨。非凸科技一直希望把性能做到最TOP的级别，对标的内容也都是现有开源代码中最好的那部分。李佐凡表示，“如果在技术层面出了错，可能一下子几十万或上百万没了，所以我们必须做到极致，持续开发和迭代才能适应新的变化。”
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/10/03-李佐凡.jpg"
                      alt="人才招聘"
                    />
                  </figure>
                  <h4 class="mb-4">三、对人才的严苛挑选</h4>
                  <p >
                    在顶尖技术的基础上，非凸科技倾向于搭建没有短板的投研团队。既有在金融行业深耕多年的业内精英，也有从互联网大厂过来的资深工程师；既有各类理工科或金融经济背景的新毕业生，也有在学术界钻研多年的相关领域专家。
                  </p>
                  <p >
                    早期的数智交易人才，能力比较复合，既要写代码、做模型、做策略，又要管理资金。然而，随着行业发展，对纵向能力的要求越来越高，比如研究深度学习在数智交易领域的应用，就要在这个领域深耕下去。李佐凡表示，“第一聪明，第二勤奋，具备这两个特质，才能在行业活下去。”
                  </p>
                  <p >
                    目前，数智交易行业对人才的需求大于供给，李佐凡认为这是好事。“数智交易行业发展很快，需要你有很强的自我驱动力，获得新的认知；反之，如果不愿意变化，就会被淘汰。”
                  </p>
                  <p >
                    除此之外，同事间的相互激发和合作同样至关重要，可以把每个人的优势发挥到最大化。在非凸，鼓励相互交流，倡导共享文化，除了经常性的分享会之外，还有信息共享平台，允许大家分享研究成果。“一方面使用起来快捷高效，另一方面激发大家乐于分享，彼此不断碰撞出新的思维火花，达到一加一大于二的效果。”李佐凡说。
                  </p>
                  <p >
                    非凸科技的管理风格扁平且高效，崇尚技术为王。同样有着ACM血统的李佐凡表示，“我们喜欢选择有竞赛经历的人，这里半数以上的工程师拿过ACM、CMO、NOI等国际奖项。”这些工程师很有特点，通常都很自律，很顽强，很优秀，愿付出，敢挑战。“问题越难，他们就越来劲。”他补充道。
                  </p>
                  <h4 class="mb-4">四、终身事业</h4>
                  <p >
                    从数智投资到策略研究，李佐凡的工作性质并没有发生本质改变。他依然聚焦数据挖掘，做模型，搞策略。他说，“从国外经验来看，科技赋能投资其实是一个终身的职业，需要投入激情和执着。”
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/10/04-李佐凡.jpg"
                      alt="人才招聘"
                    />
                  </figure>
                  <p>
                      算法策略研究给了李佐凡很强的反馈感和满足感。目前，算法交易服务主要由券商提供，每家大型券商都拥有自己的独门算法能力。但格局并非如此，非凸科技这样的第三方算法交易服务公司，以算法交易执行切入到数智交易领域，可以凭借技术和服务模式创新获得先发优势。
                  </p>
                  <p>
                      非凸智能算法是在主动算法的基础上进行了优化，在预设的交易时间内，通过算法对价格走势的预测，以最优的价格自动完成交易，获取交易环节的超额收益。李佐凡说，“非凸最新一代的算法可跑赢市场主流算法4-6bp，这其中的秘诀，在于机器学习算法的不断迭代和AI技术的应用。”
                  </p>
                  <p>
                      随着A股市场有效性提升，想要持续做出超额具有一定挑战性。因为策略出现了“同质化”，其“风险收益比”就会大幅降低。李佐凡表示，“这其实是对我们提出了更高的要求：持续开发和丰富底层因子，提升策略迭代效率以及加深对市场的理解，另外在投资组合层面持续优化模型，挖掘培养优秀人才。”
                  </p>
                                    <p>
                      非凸科技对精细技术的要求非常高。交易策略捕捉的是市场极为短暂的价格变化，巨量交易订单需要在毫秒级内由程序自动完成。“目前，非凸在建设独立专业机房，为研发人员提供更强大的算力支持。”李佐凡补充道。
                  </p>
                                    <p>
                      除此之外，非凸科技对“快”的要求也达到了极致。在李佐凡看来，这里的快，不仅包括计算机执行策略快、下单速度快，也包括其收集、处理数据形成投资策略及模型的速度快。他表示，“为了做到高性能低延迟，我们一直致力于最高效的代码和最前沿的硬件。”
                  </p>
                  <h4 class="mb-4">五、聚焦算法交易</h4>
                  <p>
                      相比传统行业，数智交易行业总在推陈出新。对科技赋能投资充满热情的李佐凡，一直在做突破，比如，在计算机体系结构上延迟和吞吐的问题，在算力上性能和计算架构的问题，以及新的计算方式，新的数据处理方式，新的AI模型等。他说，“我们没有比别人懂更多的东西，只是我们跑得更快。”
                  </p>
                  <p>
                      非凸科技的算法在交易成功率、交易速度等核心指标上均表现优异，基于Rust生态打造行业内领先的智能交易系统，满足了客户在风控、交易、数据、系统等多方面的交易需求。公司自成立以来，已为近百家金融机构提供了算法交易及执行专业化解决方案。
                  </p>
                  <p>
                      2022年，非凸科技将继续从算法领域延伸到交易执行的更多场景需求，用计算机技术、人工智能以及系统化思维，构建交易执行全场景、全周期、全覆盖的智能化升级服务与应用。在覆盖更多业务后，交易规模将实现数倍增长，预计突破1万亿。
                  </p>
                  <p>
                      凭借稳定的业绩与行业贡献度，建立机构间的口碑效应，是非凸科技一直以来的品牌目标。公司的核心竞争力在于研究和技术，着力推进智能计算、存储、网络等硬件基础设施以及框架、数据等软性基础设施建设，持续打造自主创新的国产化金融底层架构和技术能力。李佐凡表示，“至于规模多少，收益多少，这就是到了一个阶段，自然而然的目标。”
                  </p>
                  <p>
                      非凸科技用顶尖的科技、顶级的人才，最终目的是为了高效保障用户收益，让策略经得起验证。李佐凡说，“我们希望与行业同频呼吸，愿意尝试，敢于试错，让中国自己的数智交易实现跨越式发展。”
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News10",
};
</script>

<style></style>
